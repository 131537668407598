import { t } from 'i18next'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { ReactNode } from 'react'
import { IAddressReq } from '@/types/adress'
import { ICallback } from '@/types/global'
import { IMarketplaceWarehouseParams } from '@/types/marketplace-warehouses'
import { IPartner } from '@/types/partner'
import { IRunRequestDeliveryListParams } from '@/types/run-request'
import { IQubyWarehouseParams } from '@/types/warehouse_quby'
import api from '../services/api'
import { downloadBlob } from '@/utils/downloadBlob'
import errorHandler from '../utils/errorHandler'
import { notificationsStore } from './global.store'
import { AxiosError } from 'axios'

export class AppStore {
  @observable pageTitle?: string | ReactNode = ''
  @observable headerContent: ReactNode
  @observable partnerId: string | undefined
  @observable partner: IPartner | null = null

  constructor(partnerId?: string) {
    this.partnerId = partnerId
    makeObservable(this)
  }

  @action setPageTitle = (title?: string | ReactNode) => {
    runInAction(() => {
      this.pageTitle = title
    })
  }

  @action setHeaderContent = (node: ReactNode) => {
    runInAction(() => {
      this.headerContent = node
    })
  }

  @action getAddress = async (body: IAddressReq) => {
    try {
      const {
        data: { suggestions },
      } = await api.legacy.getAddress(body)
      return suggestions
    } catch (error) {
      errorHandler(error as AxiosError)
    }
  }

  @action loadMarketplaceWarehouses = async (
    params: IMarketplaceWarehouseParams = {},
  ) => {
    try {
      const { data } = await api.legacy.getMarketplaceWarehouses(params)
      return data.data
    } catch (error) {
      errorHandler(error as AxiosError)
    }
  }

  @action loadQubyWarehouses = async (params: IQubyWarehouseParams = {}) => {
    try {
      const { data } = await api.legacy.getQubyWarehouses(params)
      return data.data
    } catch (error) {
      errorHandler(error as AxiosError)
    }
  }

  // admin

  @action getDeliveryList = async (
    params: IRunRequestDeliveryListParams,
    callback: ICallback,
  ) => {
    const fileName = `Доставочный лист от ${params.withRunRequestsAtDate}`
    try {
      const { data, headers } =
        await api.legacy.getRunRequestsDeliveryList(params)
      downloadBlob({ type: headers['content-type'] }, data, `${fileName}.xlsx`)
      notificationsStore.pushSuccess({
        message: `${fileName}.xlsx загружен успешно`,
      })
      if (callback) callback()
    } catch (error) {
      errorHandler(error as AxiosError)
    }
  }
}
