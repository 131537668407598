import Api from '@/services/api/api'
import { IRegistrRes, IResetPasswordReq, ISetPasswordReq } from '@/types/auth'
import * as endpoints from '@/constants/api'
import {
  CreateShipmentApiResponse,
  UserLoginRequest,
} from '@/api/generated/qubyApiJava'
import {
  IShipment,
  IShipmentFlowBody,
  IShipmentsUploadFileParams,
  IUpdateShipmentStatusReq,
} from '@/types/shipments'
import getLink from '@/utils/getLink'
import { IPaginationDataResult } from '@/types/table'
import { IReceiverReq, IReceiverRes } from '@/types/receiver'
import { IWarehouseParams, IWarehouseRes } from '@/types/warehouse'
import { IGood, IGoodCreateReq, IGoodPrams } from '@/types/goods'
import {
  IRunRequest,
  IRunRequestCreateReq,
  IRunRequestDeliveryListParams,
  IRunRequestTableFilter,
} from '@/types/run-request'
import { IAddressReq, IAddressRes } from '@/types/adress'
import {
  IMarketplaceWarehouseParams,
  IMarketplaceWarehouseRes,
} from '@/types/marketplace-warehouses'
import { IQubyWarehouseParams, IQubyWarehouseRes } from '@/types/warehouse_quby'
import { IPartner } from '@/types/partner'
import { IAttachmentRes } from '@/types/attachments'

export class LegacyApi {
  constructor(public api: Api) {}

  createUser(body: UserLoginRequest) {
    return this.api.axios.post<IRegistrRes>(endpoints.REGISTER, body)
  }

  setPassword(body: ISetPasswordReq) {
    return this.api.axios.put(endpoints.SET_PASSWORD, body)
  }

  resetPassword(body: IResetPasswordReq) {
    return this.api.axios.post(endpoints.RESET_PASSWORD, body)
  }

  // SHIPMENTS

  getShipmentsList<T, P>(params: P) {
    return this.api.axios.get<T>(endpoints.SHIPMENTS, {
      params,
    })
  }

  getShipmentByID(id: string) {
    return this.api.axios.get<IShipment>(getLink(endpoints.SHIPMENT, { id }))
  }

  createShipment(body: IShipmentFlowBody, partnerId?: string) {
    return this.api.axios.post<CreateShipmentApiResponse>(
      endpoints.SHIPMENT_SINGLE,
      body,
      {
        params: partnerId ? { partnerId } : {},
      },
    )
  }

  updateShipment(id: string, body: Partial<IShipmentFlowBody>) {
    return this.api.axios.put<unknown>(
      getLink(endpoints.SHIPMENT, { id }),
      body,
    )
  }

  updateShipmentStatus(id: string, body: IUpdateShipmentStatusReq) {
    return this.api.axios.patch<unknown>(
      getLink(endpoints.SHIPMENT, { id }),
      body,
    )
  }

  uploadShipmentsMultiple(file: FormData, params: IShipmentsUploadFileParams) {
    return this.api.axios.post(
      endpoints.SHIPMENT_UPLOAD_FILE_MULTIPLE_CREATION,
      file,
      {
        params,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
  }

  // RECEIVERS

  getReceivers<T, P>(params: P) {
    return this.api.axios.get<IPaginationDataResult<T>>(endpoints.RECEIVERS, {
      params,
    })
  }

  createReceiver(body: IReceiverReq, partnerId?: string) {
    return this.api.axios.post<IReceiverRes>(endpoints.RECEIVER, body, {
      params: partnerId ? { partnerId } : {},
    })
  }

  updateReceiver(id: string, body: Partial<IReceiverReq>) {
    return this.api.axios.put<IReceiverRes>(
      getLink(endpoints.RECEIVER_SINGLE, { id }),
      body,
    )
  }

  getWarehouses(params?: IWarehouseParams) {
    return this.api.axios.get<IPaginationDataResult<IWarehouseRes[]>>(
      endpoints.WAREHOUSES,
      { params },
    )
  }

  // GOODS

  getGoods(params?: IGoodPrams) {
    return this.api.axios.get<IPaginationDataResult<IGood[]>>(endpoints.GOODS, {
      params,
    })
  }

  crateGoods(body: IGoodCreateReq[], partnerId?: string) {
    return this.api.axios.post<IGood[]>(endpoints.GOODS, body, {
      params: partnerId ? { partnerId } : {},
    })
  }

  // RUN_REQUESTS
  getRunRequestsList<T>(params: IRunRequestTableFilter) {
    return this.api.axios.get<T>(endpoints.RUN_REQUESTS, { params })
  }

  getRunRequestByID(id: string) {
    return this.api.axios.get<IRunRequest>(
      getLink(endpoints.RUN_REQUEST, { id }),
    )
  }

  createRunRequest(body: Partial<IRunRequestCreateReq>, partnerId?: string) {
    return this.api.axios.post<IRunRequest>(
      endpoints.RUN_REQUEST_SINGLE,
      body,
      {
        params: partnerId ? { partnerId } : {},
      },
    )
  }

  updateRunRequest(id: string, body: IRunRequestCreateReq) {
    return this.api.axios.put<IRunRequest>(
      getLink(endpoints.RUN_REQUEST, { id }),
      body,
    )
  }

  getRunRequestsDeliveryList(params: IRunRequestDeliveryListParams) {
    return this.api.axios.get(
      '/v1.1/internal/run-requests/export/delivery-list',
      {
        responseType: 'blob',
        params,
      },
    )
  }

  getAddress(body?: IAddressReq) {
    return this.api.axios.post<IAddressRes>(endpoints.DADATA_ADDRESS, body)
  }

  // MARKETPLACE_WAREHOUSES

  getMarketplaceWarehouses(params?: IMarketplaceWarehouseParams) {
    return this.api.axios.get<
      IPaginationDataResult<IMarketplaceWarehouseRes[]>
    >(endpoints.MARKETPLACE_WAREHOUSES, { params })
  }

  // QUBY_WAREHOUSES

  getQubyWarehouses(params?: IQubyWarehouseParams) {
    return this.api.axios.get<IPaginationDataResult<IQubyWarehouseRes[]>>(
      endpoints.QUBY_WAREHOUSES,
      { params },
    )
  }

  // PARTNER

  getPartner(partnerId?: string) {
    return this.api.axios.get<IPartner>(endpoints.PARTNER, {
      params: {
        ...(partnerId ? { partnerId } : {}),
      },
    })
  }

  getPartnerList<T, P>(params: P) {
    return this.api.axios.get<T>(endpoints.PARTNERS, { params })
  }

  // DELIVERY SERVICES

  getDeliveryServices<T, P>(params: P) {
    return this.api.axios.get<T>(endpoints.DELIVERY_SERVICES, { params })
  }

  createAttachment(model: string, modelId: string, body: FormData) {
    return this.api.axios.post<IAttachmentRes>(
      getLink(endpoints.ATTACHMENTS, { model }),
      body,
      {
        params: { modelId },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    )
  }

  deleteAttachment(model: string, fileId: string) {
    return this.api.axios.delete(getLink(endpoints.ATTACHMENTS, { model }), {
      params: { 'file-id': fileId },
    })
  }
}
