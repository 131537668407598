/**
 * Дерево сейчас не взаимодействует с хуками и может подключаться только импортом.
 *
 * Можно наделить его особыми методами для перехода по истории например,
 * но так мы накладываем прослойку между компонентами роутера
 * и будем вынуждены писать больше документации.
 */

import { InAppContextType, NavIBranchLeaf, NavIRoot } from './NavICore'
import { ShipmentCardTabNames } from '@/containers/ShipmentCard/ShpmentCard.types'
// import { RunRequestTabNames } from '@/containers/RunRequestCard/RunRequestCard.types'
import { RunRequestTabNames } from '@/_app/pages/runRequest/RunRequestCard/RunRequestCard.types'
import { Role } from '@/api/generated/qubyApiJava'
import {
  StatisticsOrderTabNames,
  StatisticsSDTabNames,
} from '@/_app/pages/statistics/statisticsOrder/StatisticsOrder.types'
import { MonitoringErrorTypeWithoutDefault } from '@/api/qubyApiEgoDlv'
import { RunRequestTypesEnum } from '@/api/generated/qubyApiPhp'

const qubyEmployeeRoles: Role[] = [
  'QUBY_MANAGER',
  'QUBY_ADMIN',
  'QUBY_DISPATCHER',
]

/**
 * Дерево Навигации
 * --
 *
 * Дерево навигации решает проблемы генерации роутов в ссылках и рефакторинга путей.
 *
 * Дерево отражает вложенность навигации и строение путей,
 * а за счёт передачи контекста, может строить пути для роутов и для ссылок.
 *
 * В случае изменений роутинга, линтер сообщит, что используются не те пути и можно будет исправить.
 *
 * Это дерево подходит как для пятой версии роутера, так и имеет задел для использования в шестой.
 *
 * В отличие от сигнатуры агругемента `useRoutes`, вложенные роуты не погружаются в children и не имеют компонентов,
 * но можно сгенерировать useRoutes-подобный массив из дерева.
 *
 * Указание компонентов в дереве, может быть подспорьем в отладке для быстрого нахождения компонента.
 */
class QubyAppNav extends NavIRoot {
  PATH = '/'

  clients = new (class extends NavIBranchLeaf {
    PATH_PART = 'clients'
    create = new (class extends NavIBranchLeaf {
      PATH_PART = 'create'
    })(this)
  })(this)

  runRequests = new (class extends NavIBranchLeaf {
    PATH_PART = 'run-requests'

    edit = new (class extends NavIBranchLeaf<{ runRequestId: string }> {
      PATH_PART = 'edit/:runRequestId'
      tab = new (class extends NavIBranchLeaf<{
        runRequestId: string
        tabName?: RunRequestTabNames
      }> {
        PATH_PART = ':tabName?'
      })(this)
    })(this)

    create = new (class extends NavIBranchLeaf {
      PATH_PART = 'create'
      tab = new (class extends NavIBranchLeaf<{
        tabName?: RunRequestTabNames
      }> {
        PATH_PART = ':tabName?'
      })(this)
    })(this)
  })(this)
  runRequest = new (class extends NavIBranchLeaf {
    PATH_PART = 'run-request'

    edit = new (class extends NavIBranchLeaf<{ runRequestId: string }> {
      PATH_PART = 'edit/:runRequestId'
      tab = new (class extends NavIBranchLeaf<{
        runRequestId: string
        tabName?: RunRequestTabNames
      }> {
        PATH_PART = ':tabName?'
      })(this)
    })(this)

    create = new (class extends NavIBranchLeaf {
      PATH_PART = 'create'
      tab = new (class extends NavIBranchLeaf<{
        tabName?: RunRequestTabNames
        shipmentId?: string
        isReturn?: RunRequestTypesEnum
      }> {
        PATH_PART = ':tabName?/:shipmentId?/:isReturn?'
      })(this)
    })(this)
  })(this)

  yandexRoutes = new (class extends NavIBranchLeaf {
    PATH_PART = 'yandex-routes'
  })(this)

  acceptance = new (class extends NavIBranchLeaf {
    PATH_PART = 'acceptance'
  })(this)

  returns = new (class extends NavIBranchLeaf {
    PATH_PART = 'returns'
  })(this)
  return = new (class extends NavIBranchLeaf {
    PATH_PART = 'return'
  })(this)

  invoices = new (class extends NavIBranchLeaf {
    PATH_PART = 'invoices'
  })(this)

  transferToTheDeliveryService = new (class extends NavIBranchLeaf {
    PATH_PART = 'transfer-to-the-delivery-service'
  })(this)

  login = new (class extends NavIBranchLeaf {
    PATH_PART = 'login'
  })(this)

  kg = new (class extends NavIBranchLeaf {
    PATH_PART = 'kg'
  })(this)
  dm = new (class extends NavIBranchLeaf {
    PATH_PART = 'dm'
  })(this)

  logout = new (class extends NavIBranchLeaf {
    PATH_PART = 'logout'
  })(this)

  resetPassword = new (class extends NavIBranchLeaf {
    PATH_PART = 'reset-password'
    success = new (class extends NavIBranchLeaf {
      PATH_PART = 'success'
    })(this)
  })(this)

  registration = new (class extends NavIBranchLeaf {
    PATH_PART = 'register'
    success = new (class extends NavIBranchLeaf {
      PATH_PART = 'success'
    })(this)
  })(this)

  monitoring = new (class extends NavIBranchLeaf {
    PATH_PART = 'monitoring-dlv'
    edit = new (class extends NavIBranchLeaf<{ dlvId: string }> {
      PATH_PART = 'edit/:dlvId'
    })(this)
  })(this)

  shipmentsDlv = new (class extends NavIBranchLeaf {
    PATH_PART = 'shipments-dlv'
  })(this)
  returnsDlv = new (class extends NavIBranchLeaf {
    PATH_PART = 'returns-dlv'
  })(this)

  setPassword = new (class extends NavIBranchLeaf {
    PATH_PART = 'set-password'
  })(this)

  registers = new (class extends NavIBranchLeaf {
    PATH_PART = 'registers'

    users = new (class extends NavIBranchLeaf {
      PATH_PART = 'users'
      onlyForContext = ['CLIENT'] as InAppContextType[]
      onlyForRoles = qubyEmployeeRoles
    })(this)

    marketplaceWarehouses = new (class extends NavIBranchLeaf {
      PATH_PART = 'marketplace-warehouses'
    })(this)

    vehicles = new (class extends NavIBranchLeaf {
      PATH_PART = 'vehicles'
      onlyForContext = ['ROOT'] as InAppContextType[]
      onlyForRoles = qubyEmployeeRoles
    })(this)
    drivers = new (class extends NavIBranchLeaf {
      PATH_PART = 'drivers'
      onlyForContext = ['ROOT'] as InAppContextType[]
      onlyForRoles = qubyEmployeeRoles
    })(this)
    receivers = new (class extends NavIBranchLeaf {
      PATH_PART = 'receivers'
      onlyForContext = ['CLIENT'] as InAppContextType[]
    })(this)

    deliveryServices = new (class extends NavIBranchLeaf {
      PATH_PART = 'delivery-services'
      onlyForContext = ['ROOT'] as InAppContextType[]
      onlyForRoles = qubyEmployeeRoles
    })(this)
    partnerWarehouses = new (class extends NavIBranchLeaf {
      PATH_PART = 'partner-warehouses'
      onlyForContext = ['CLIENT'] as InAppContextType[]
    })(this)
    goods = new (class extends NavIBranchLeaf {
      PATH_PART = 'goods'
      onlyForContext = ['CLIENT'] as InAppContextType[]
    })(this)
  })(this)

  shipments = new (class extends NavIBranchLeaf {
    PATH_PART = 'shipments'
    export = new (class extends NavIBranchLeaf {
      PATH_PART = 'export'
    })(this)

    create = new (class extends NavIBranchLeaf {
      PATH_PART = 'create'

      tab = new (class extends NavIBranchLeaf<{
        tabName: ShipmentCardTabNames
      }> {
        PATH_PART = ':tabName?'
      })(this)
    })(this)

    createFromId = new (class extends NavIBranchLeaf<{ shipmentId: string }> {
      PATH_PART = 'create-from-id/:shipmentId'
    })(this)

    edit = new (class extends NavIBranchLeaf<{ shipmentId: string }> {
      PATH_PART = 'edit/:shipmentId'
      tab = new (class extends NavIBranchLeaf<{
        shipmentId: string
        tabName: ShipmentCardTabNames
      }> {
        PATH_PART = ':tabName?'
      })(this)
    })(this)
  })(this)

  statisticsOrder = new (class extends NavIBranchLeaf {
    PATH_PART = 'statistics-order'
    tab = new (class extends NavIBranchLeaf<{
      tabName: StatisticsOrderTabNames
    }> {
      PATH_PART = ':tabName?'
    })(this)
  })(this)
  statisticsSD = new (class extends NavIBranchLeaf {
    PATH_PART = 'statistics-sd'
    tab = new (class extends NavIBranchLeaf<{
      tabName: StatisticsSDTabNames
    }> {
      PATH_PART = ':tabName?'
    })(this)
  })(this)
}

/**
 * Навигация в приложении
 * --
 */
export const qubyNav = new QubyAppNav()
