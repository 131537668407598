import { AxiosError, AxiosResponse } from 'axios'
import { parseFilenameFromContentDisposition } from '@/utils/parseFilenameFromContentDisposition'
import { downloadBlob } from '@/utils/downloadBlob'
import Api from '@/services/api/api'
import {
  DownloadActOfAcceptanceDeliveryApiArg,
  DownloadConsignmentNoteApiArg,
  DownloadInsuranceReportApiArg,
  DownloadServiceRegisterFileApiArg,
  DownloadShipmentExportFileApiArg,
  GetBarcodeStickerApiArg,
  GetBarcodeStickersApiArg,
  GetDlvPrintFormApiArg,
} from '@/api/generated/qubyApiJava'
import getLink from '@/utils/getLink'
import { notificationsStore } from '@/stores/global.store'
import errorHandler from '@/utils/errorHandler'
import {
  GetInternalRunRequestDeliveryListExportApiArg,
  GetInternalShipmentsRegisterExportApiArg,
} from '@/api/generated/qubyApiPhp'
import qs from 'qs'

const envVariables = require('@/constants/env')

const downloadFileToUser = async (
  axiosPromise: Promise<AxiosResponse>,
  fileName?: string,
  options?: { preventShowError?: boolean },
) => {
  try {
    notificationsStore.pushSuccess({ message: 'Создаю файл' })
    const response = await axiosPromise
    const { data, headers } = response
    downloadBlob(
      { type: headers['content-type'] },
      data,
      fileName ||
        parseFilenameFromContentDisposition(headers['content-disposition']),
    )
    if (!options?.preventShowError) {
      notificationsStore.pushSuccess({ message: 'Файл создан' })
    }
    return response
  } catch (error) {
    const err = error as AxiosError
    errorHandler(err)
    return err
  }
}

export class ApiDownload {
  constructor(private readonly api: Api) {}

  readonly shipmentsExportFile = async (
    params: DownloadShipmentExportFileApiArg,
  ) => {
    return await this.download({
      url: '/internal/export/shipmentsRegister',
      params,
    })
  }
  readonly shipmentsInternalExportFile = async (
    params: GetInternalShipmentsRegisterExportApiArg,
  ) => {
    return await this.download({
      url: `${envVariables.PHP_API_URL}v1.1/export/shipments-register`,
      params,
      isPreventParseParams: true,
      isPhpParamsSerializer: true,
    })
  }
  readonly getRunRequestsDeliveryList = async (
    params: GetInternalRunRequestDeliveryListExportApiArg,
  ) => {
    return await this.download({
      url: `${envVariables.PHP_API_URL}v1.1/internal/run-requests/export/delivery-list`,
      params,
      isPreventParseParams: true,
      isPhpParamsSerializer: true,
    })
  }

  readonly barcode = async (params: GetBarcodeStickerApiArg) => {
    return await this.download({
      url: '/shipment/:shipmentOrdinalNumber/barcode/:barcode',
      params,
    })
  }

  readonly insuranceReport = async (params: DownloadInsuranceReportApiArg) => {
    return await this.download({
      url: `${envVariables.PHP_API_URL}v1.1/internal/export/insurance-register`,
      params,
      isPreventParseParams: true,
    })
  }

  readonly serviceRegisterFile = async (
    params: DownloadServiceRegisterFileApiArg,
    options: { preventShowError: boolean },
  ) => {
    return await this.download(
      {
        url: `${envVariables.PHP_API_URL}v1.1/internal/export/provided-services-register`,
        params,
        isPreventParseParams: true,
      },
      options,
    )
  }

  readonly individualTariffs = async (params: { partnerId: string }) => {
    return await this.download({
      url: `${envVariables.PHP_API_URL}v1.1/internal/export/provided-services-register`,
      params,
      isPreventParseParams: true,
    })
  }

  readonly barcodes = async (params: GetBarcodeStickersApiArg) => {
    return await this.download({
      url: '/shipment/:shipmentOrdinalNumber/barcodes',
      params,
    })
  }

  readonly consignmentNote = async (params: DownloadConsignmentNoteApiArg) => {
    return await this.download({
      url: '/run-requests/:requestId/consignment-note',
      params,
    })
  }

  readonly externalServiceBarcodes = async (params: GetDlvPrintFormApiArg) => {
    return await this.download({
      url: '/dlv-print-form',
      params,
    })
  }

  readonly actOfAcceptanceDelivery = async (
    params: DownloadActOfAcceptanceDeliveryApiArg,
  ) => {
    return await this.download({
      url: '/internal/export/act-of-acceptance-delivery',
      params,
    })
  }

  readonly xlsxShipmentsImportTemplate = async () => {
    return await this.download({
      url: '/download/import-template',
    })
  }

  readonly urlDownload = async (url: string, fileName?: string) => {
    return this.download({
      url,
      fileName,
    })
  }

  private readonly download = async (
    {
      url,
      params,
      fileName,
      isPreventParseParams,
      isPhpParamsSerializer,
    }: {
      url: string
      params?: Record<string, unknown>
      fileName?: string
      isPreventParseParams?: boolean
      isPhpParamsSerializer?: boolean
    },
    options?: { preventShowError?: boolean },
  ) => {
    const axiosPromise = this.api.axios.get<BlobPart>(
      params && !isPreventParseParams ? getLink(url, params) : url,
      {
        responseType: 'blob',
        params,
        ...(isPhpParamsSerializer ?
          {
            paramsSerializer: {
              serialize: (params) => {
                return qs.stringify(params)
              },
            },
          }
        : {}),
      },
    )
    return await downloadFileToUser(axiosPromise, fileName, options)
  }
}
