import { RouteProps } from 'react-router-dom'
import { qubyNav } from '@/navigation/qubyNav'
import {
  RunRequestFlowLazy,
  RunRequestsLazy,
  ShipmentCardLazy,
  ShipmentFromId,
  ShipmentsExportLazy,
  ShipmentsLazy,
  TransferToTheDeliveryServiceLazy,
  YandexRoutesLazy,
} from '@/routes/lazy'
import { ReceiversLazyPage } from '@/_app/pages/receivers/ReceiversLazyPage'
import { RegistersLazyPage } from '@/_app/pages/registers/RegistersLazyPage'
import { InvoicesLazyPage } from '@/_app/pages/registers/invoices/InvoicesLazyPage'
import { VehiclesLazyPage } from '@/_app/pages/registers/vehicles/VehiclesLazyPage'
import { DriversLazyPage } from '@/_app/pages/registers/drivers/DriversLazyPage'
import { ClientsLazyPage } from '@/_app/pages/partners/ClientsLazyPage'
import React from 'react'
import { DeliveryServicesLazyPage } from '@/_app/pages/registers/deliveryServices/DeliveryServicesLazyPage'
import { GoodsLazyPage } from '@/_app/pages/registers/goods/GoodsLazyPage'
import { PartnerWarehousesLazyPage } from '@/_app/pages/registers/partnerWarehouses/PartnerWarehousesLazyPage'
import { MarketplaceWarehousesLazyPage } from '@/_app/pages/registers/marketplaces/MarketplaceWarehousesLazyPage'
import { UsersLazyPage } from '@/_app/pages/registers/users/UsersLazyPage'
import { ClientCreateLazyPage } from '@/_app/pages/partners/create/ClientCreateLazyPage'
import { MonitoringLazyPage } from '@/_app/pages/monitoringDlv/MonitoringLazyPage'
import { StatisticsOrderLazyPage } from '@/_app/pages/statistics/statisticsOrder/StatisticsOrderLazyPage'
import { StatisticsSDLazyPage } from '@/_app/pages/statistics/statisticsSD/StatisticsSDLazyPage'
import { AcceptanceLazyPage } from '@/_app/pages/acceptance/AcceptanceLazyPage'
import { ShipmentsDlvLazyPage } from '@/_app/pages/shipmentsDlv/ShipmentsDlvLazyPage'
import { MonitoringCardLazyPage } from '@/_app/pages/monitoringDlv/MonitoringCard/MonitoringCardLazyPage'
import { RunRequestLazyPage } from '@/_app/pages/runRequest/RunRequestLazyPage'
import { RunRequestCardLazyPage } from '@/_app/pages/runRequest/RunRequestCard/RunRequestCardLazyPage'
import { ReturnsDlvLazyPage } from '@/_app/pages/returnsDlv/ReturnsDlvLazyPage'

export const privateRoutes: RouteProps[] = [
  {
    path: qubyNav.registers.marketplaceWarehouses.PATH,
    element: <MarketplaceWarehousesLazyPage />,
  },
  {
    path: qubyNav.clients.create.PATH,
    element: <ClientCreateLazyPage />,
  },
  {
    path: qubyNav.registers.receivers.PATH,
    element: <ReceiversLazyPage />,
  },
  {
    path: qubyNav.invoices.PATH,
    element: <InvoicesLazyPage />,
  },
  {
    path: qubyNav.registers.PATH,
    element: <RegistersLazyPage />,
  },
  {
    path: qubyNav.registers.vehicles.PATH,
    element: <VehiclesLazyPage />,
  },
  {
    path: qubyNav.registers.partnerWarehouses.PATH,
    element: <PartnerWarehousesLazyPage />,
  },
  {
    path: qubyNav.registers.drivers.PATH,
    element: <DriversLazyPage />,
  },
  {
    path: qubyNav.registers.users.PATH,
    element: <UsersLazyPage />,
  },
  {
    path: qubyNav.registers.goods.PATH,
    element: <GoodsLazyPage />,
  },
  {
    path: qubyNav.registers.deliveryServices.PATH,
    element: <DeliveryServicesLazyPage />,
  },
  {
    path: qubyNav.clients.PATH,
    element: <ClientsLazyPage />,
  },
  {
    path: qubyNav.shipments.PATH,
    element: <ShipmentsLazy />,
  },
  {
    path: qubyNav.shipments.createFromId.PATH,
    element: <ShipmentFromId />,
  },
  {
    path: qubyNav.shipments.create.tab.PATH,
    element: <ShipmentCardLazy />,
  },
  {
    path: qubyNav.shipments.edit.tab.PATH,
    element: <ShipmentCardLazy />,
  },

  {
    path: qubyNav.shipments.export.PATH,
    element: <ShipmentsExportLazy />,
  },
  {
    path: qubyNav.runRequests.PATH,
    element: <RunRequestsLazy />,
  },
  //New RunRequest
  {
    path: qubyNav.runRequest.PATH,
    element: <RunRequestLazyPage />,
  },
  {
    path: qubyNav.returns.PATH,
    element: <RunRequestsLazy runRequestType={'returnsOnly'} />,
  },
  //New RunRequest
  {
    path: qubyNav.return.PATH,
    element: <RunRequestLazyPage runRequestType={'returnsOnly'} />,
  },
  {
    path: qubyNav.runRequests.create.tab.PATH,
    element: <RunRequestFlowLazy />,
  },
  {
    path: qubyNav.runRequests.edit.tab.PATH,
    element: <RunRequestFlowLazy />,
  },
  //New RunRequest
  {
    path: qubyNav.runRequest.edit.tab.PATH,
    element: <RunRequestCardLazyPage />,
  },
  {
    path: qubyNav.runRequest.create.tab.PATH,
    element: <RunRequestCardLazyPage />,
  },
  {
    path: qubyNav.acceptance.PATH,
    element: <AcceptanceLazyPage />,
  },
  {
    path: qubyNav.yandexRoutes.PATH,
    element: <YandexRoutesLazy />,
  },
  {
    path: qubyNav.transferToTheDeliveryService.PATH,
    element: <TransferToTheDeliveryServiceLazy />,
  },
  {
    path: qubyNav.monitoring.PATH,
    element: <MonitoringLazyPage />,
  },
  {
    path: qubyNav.monitoring.edit.PATH,
    element: <MonitoringCardLazyPage />,
  },
  {
    path: qubyNav.shipmentsDlv.PATH,
    element: <ShipmentsDlvLazyPage />,
  },
  {
    path: qubyNav.statisticsOrder.tab.PATH,
    element: <StatisticsOrderLazyPage />,
  },
  {
    path: qubyNav.statisticsSD.tab.PATH,
    element: <StatisticsSDLazyPage />,
  },
  {
    path: qubyNav.returnsDlv.PATH,
    element: <ReturnsDlvLazyPage />,
  },
]
